const puzzleConfigurations = [
    [
        [1, 0, 3], //3 moves
        [4, 2, 5],
        [7, 8, 6]
    ],
    [
        [0, 2, 3], //4 moves
        [1, 5, 6],
        [4, 7, 8]
    ],
    [
        [0, 1, 3], //4 moves
        [4, 2, 5],
        [7, 8, 6]
    ],
    [
        [1, 3, 0], // 4 moves
        [4, 2, 5],
        [7, 8, 6]
    ],
    [
        [4, 1, 3], //6 moves
        [2, 0, 6],
        [7, 5, 8]
    ],
    [
        [4, 1, 3], //7 moves
        [2, 5, 0],
        [7, 8, 6]
    ],
    [
        [2, 4, 3], // 7 moves
        [1, 5, 6],
        [7, 0, 8]
    ],
    [
        [4, 1, 2], // 8 moves
        [7, 0, 3],
        [8, 5, 6]
    ],
    [
        [4, 1, 3], //8 moves
        [2, 5, 6],
        [7, 8, 0]
    ],
    [
        [2, 4, 3], //8 moves
        [1, 5, 6],
        [7, 8, 0]
    ],
    [
        [7, 1, 3], //12 moves
        [2, 0, 4],
        [8, 6, 5]
    ],
    [
        [5, 2, 1], // 14 moves
        [4, 0, 3],
        [7, 8, 6]
    ]
];

export default puzzleConfigurations;
